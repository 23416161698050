import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { get } from 'lodash';
import Helmet from 'react-helmet';

import CallIcon from '@material-ui/icons/Call';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import ChairIcon from '@material-ui/icons/Deck';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import VideoCamIcon from '@material-ui/icons/Videocam';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PoolIcon from '@material-ui/icons/Pool';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ParkIcon from '@material-ui/icons/Spa';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Gallery from '../../components/Gallery';
import FaceboookIcon from './facebook';
import InstagramIcon from './instagram';

import messages from '../../getMessages';
import { BASE_URL } from '../../helper';

import {
  Container,
  Banner,
  EventBrief,
  Services,
  ServiceItem,
  Contact,
  Footer,
  FindUs,
} from './styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialBody = {
  nombre: '',
  numero: '',
  email: '',
  tipo: '',
  cantidad: '',
  mensaje: '',
};

export default function EventDetail({ children, salon, data }) {
  const [body, setBody] = useState({ ...initialBody });
  const [successOpen, setSuccessOpen] = useState('');
  const [errorOpen, setErrorOpen] = useState('');
  const [avisoPrivacidadChecked, setAvisoPrivacidadChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const handleRequestInformation = async () => {
    try {
      const response = await Axios
        .post(`${BASE_URL}/maioris/send-event-request`,
          { ...body, evento: salon }
        );

      const success = get(response, 'data.data.success', false);
      if (!success) {
        setErrorOpen(messages.event.error);
        return;
      }

      setBody({ ...initialBody });
      setSuccessOpen(messages.event.success);
    } catch (e) {
      setErrorOpen(messages.event.error);
    } finally {

    }
  };

  const handleSetBody = (key, value) =>
    setBody(_body => ({
      ..._body,
      [key]: value,
    }));

  const handleClose = () => {
    setSuccessOpen('');
    setErrorOpen('');
  };

  const [
    primaryColor,
    phone,
    phoneFormated,
    logo,
    alt,
    logoHeight,
    background2,
    backgroundColor,
    name,
    description,
    services,
    iconColor,
    facebook,
    instagram,
    linkNoticePrivacy,
    mapsKey,
  ] = (() => {
    switch (salon) {
      case 'via-esperanza':
        return [
          '#00A690',
          '6677215918',
          '(667) 721 59 18',
          '/eventos/viaesperanza.png',
          'Logo salón Vía Esperanza',
          '64px',
          'background-viaesperanza.jpg',
          '#D8EFEC',
          'Salón Vía Esperanza',
          messages.event.viaEsperanzaDesc1,
          messages.event.viaEsperanzaDesc2,
          '#028070',
          `https://www.facebook.com/ViaEsperanzaEventos`,
          `https://www.instagram.com/viaesperanza/?hl=es`,
          '/aviso-privacidad-via-esperanza.pdf',
          'place_id:ChIJ2Rrk-JnQvIYRsM8G1XqfuJ8',
        ];
      case 'via-petra':
        return [
          '#A6726B',
          '6675310141',
          '(667) 531 01 41',
          '/eventos/viapetra.png',
          'Logo salón Vía Petra',
          '56px',
          'via-petra/14.jpg',
          '#EED6C9',
          'Salón Vía Petra',
          messages.event.viaPetraDesc1,
          messages.event.viaPetraDesc2,
          '#A6726B',
          'https://www.facebook.com/viapetraeventos',
          'https://www.instagram.com/viapetraeventos/',
          '/aviso-privacidad-via-petra.pdf',
          'place_id:ChIJPwZ97xrFvIYRqtDqPg2TQFw',
        ];
      case 'maioris-beach-club':
      default:
        return [
          '#D8B93C',
          '6121246600',
          '(612) 124 66 00',
          '/eventos/beachclub.png',
          'Logo Maioris beach club',
          '44px',
          'beach-club/1.jpg',
          '#f4ead4',
          'Maioris Beach Club',
          messages.event.beachClubDesc1,
          messages.event.beachClubDesc2,
          '#D8B93C',
          'https://www.facebook.com/MaiorisBeachClub',
          'https://www.instagram.com/maiorisbcmx/?hl=es',
          '/aviso-privacidad-maioris-beach-club.pdf',
          'place_id:ChIJCdE8hAgtroYR2YB3mYeNwGA',
        ];
    }
  })();

  const images = JSON.parse(get((get(data, 'places') || []).find(p => p.place.replace('beach-club', 'maioris-beach-club') === salon), 'gallery') || '[]');
  const background = get((get(data, 'places') || []).find(p => p.place.replace('beach-club', 'maioris-beach-club') === salon), 'banner');
  // const images = (() => {
  //   switch (salon) {
  //     case 'via-esperanza':
  //       return [
  //         '/eventos/via-esperanza/1.jpg',
  //         '/eventos/via-esperanza/2.jpg',
  //         '/eventos/via-esperanza/4.jpg',
  //         '/eventos/via-esperanza/5.jpg',
  //         '/eventos/via-esperanza/6.jpg',
  //         '/eventos/via-esperanza/7.jpg',
  //         '/eventos/via-esperanza/8.jpg',
  //         '/eventos/via-esperanza/9.jpg',
  //         '/eventos/via-esperanza/10.jpg',
  //         '/eventos/via-esperanza/11.jpg',
  //         '/eventos/via-esperanza/12.jpg',
  //         '/eventos/via-esperanza/13.jpg',
  //         '/eventos/via-esperanza/14.jpg',
  //         '/eventos/via-esperanza/15.jpg',
  //         '/eventos/via-esperanza/16.jpg',
  //         '/eventos/via-esperanza/17.jpg',
  //         '/eventos/via-esperanza/18.jpg',
  //         '/eventos/via-esperanza/19.jpg',
  //         '/eventos/via-esperanza/20.jpg',
  //         '/eventos/via-esperanza/21.jpg',
  //         '/eventos/via-esperanza/22.jpg',
  //       ];
  //     case 'via-petra':
  //       return [
  //         '/eventos/via-petra/1.jpg',
  //         '/eventos/via-petra/2.jpg',
  //         '/eventos/via-petra/3.jpg',
  //         '/eventos/via-petra/4.jpg',
  //         '/eventos/via-petra/5.jpg',
  //         '/eventos/via-petra/6.jpg',
  //         '/eventos/via-petra/7.jpg',
  //         '/eventos/via-petra/8.jpg',
  //         '/eventos/via-petra/9.jpg',
  //         '/eventos/via-petra/10.jpg',
  //         '/eventos/via-petra/11.jpg',
  //         '/eventos/via-petra/12.jpg',
  //         '/eventos/via-petra/13.jpg',
  //         '/eventos/via-petra/15.jpg',
  //         '/eventos/via-petra/16.jpg',
  //         '/eventos/via-petra/17.jpg',
  //         '/eventos/via-petra/18.jpg',
  //       ];
  //     case 'maioris-beach-club':
  //     default:
  //       return [
  //         '/eventos/beach-club/2.jpg',
  //         '/eventos/beach-club/3.jpg',
  //         '/eventos/beach-club/4.jpg',
  //         '/eventos/beach-club/5.jpg',
  //         '/eventos/beach-club/6.jpg',
  //         '/eventos/beach-club/7.jpg',
  //         '/eventos/beach-club/8.jpg',
  //         '/eventos/beach-club/9.jpg',
  //         '/eventos/beach-club/10.jpg',
  //         '/eventos/beach-club/11.jpg',
  //         '/eventos/beach-club/12.jpg',
  //         '/eventos/beach-club/13.jpg',
  //         '/eventos/beach-club/14.jpg',
  //         '/eventos/beach-club/15.jpg',
  //       ];
  //   }
  // })();
  const servicesList = (() => {
    switch (salon) {
      case 'via-esperanza':
        return [
          <ServiceItem>
            <FavoriteBorderIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.decorationsAdvisor}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.trainedStaff}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <RestaurantIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.exclusiveBanquet}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VolumeUpIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.lightsAndSound}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.eventCoordinator}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LoyaltyIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.innovativeDesign}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <ParkIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.gardenOnly}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <AcUnitIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.airConditioning}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <ChairIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.furnitureRental}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VideoCamIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.surveillance}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LocalParkingIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.parking}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <DirectionsCarIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.valet}
            </div>
          </ServiceItem>,
        ];
      case 'via-petra':
        return [
          <ServiceItem>
            <FavoriteBorderIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.decorationsAdvisor}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.trainedStaff}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <RestaurantIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.exclusiveBanquet}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VolumeUpIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.lightsAndSound}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <AcUnitIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.airConditioning}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LoyaltyIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.innovativeDesign}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.eventCoordinator}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VideoCamIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.surveillance}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LocalParkingIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.valetParking}
            </div>
          </ServiceItem>,
        ];
      case 'maioris-beach-club':
      default:
        return [
          <ServiceItem>
            <FavoriteBorderIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.decorationsAdvisor}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.trainedStaff}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <RestaurantIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.exclusiveBanquet}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VolumeUpIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.lightsAndSound}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PoolIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.garden}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LoyaltyIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.innovativeDesign}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <PersonOutlinedIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.eventCoordinator}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <VideoCamIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.surveillance}
            </div>
          </ServiceItem>,
          <ServiceItem>
            <LocalParkingIcon style={{ color: iconColor }} />
            <div>
              {messages.eventServices.parking}
            </div>
          </ServiceItem>,
        ];
    }
  })();

  const [pageTitle, pageImage, pageDescription] = (() => {
    switch (salon) {
      case 'via-esperanza':
        return [
          'Salón Vía Esperanza',
          'https://hotelesmaioris.com.mx/eventos/viaesperanza.png',
          'En Vía Esperanza eventos hay espacio suficiente para que les des vida a tu celebración, rodeado por jardines en los que puedes crear distintas atmósferas.',
        ];
      case 'via-petra':
        return [
          'Salón Vía Petra',
          'https://hotelesmaioris.com.mx/eventos/viapetra.png',
          'Somos un equipo especializado en la coordinación y realización de eventos sociales y empresariales, ofreciendo un servicio integral que facilita al cliente la organización de su celebración.',
        ];
      case 'maioris-beach-club':
      default:
        return [
          'Maioris Beach Club',
          'https://hotelesmaioris.com.mx/eventos/beachclub.png',
          'Con un hermoso Jardín y una palapa acondicionada para hacer de tu evento una hermosa e inolvidable experiencia',
        ];
    }
  })();

  const disabled = !avisoPrivacidadChecked || !termsChecked;

  return (
    <Container color={primaryColor} secondaryColor={primaryColor}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:image" content={pageImage} />
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Banner
        color={primaryColor}
        logoHeight={logoHeight}
        background={background}
      >
        <div className="layer" />
        <div className="over-content">
          <div className="content">
            <div className="space-between">
              <div className="logo-section">
                <Link to="/">
                  <img src={logo} alt={alt} />
                </Link>
              </div>
              <nav>
                <a href={`tel:${phone}`} className="phone">
                  <CallIcon />
                  {phoneFormated}
                </a>
                <Link to="/" className="reservar-button">
                  Hotel
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </Banner>
      <EventBrief backgroundColor={backgroundColor}>
        <div className="content">
          <h1>{name}</h1>
          <div className="description">{description}</div>
        </div>
      </EventBrief>
      <Services>
        <div className="content">
          <h2>{messages.services.services}</h2>
          <div className="description">{services}</div>
          <div className="services-list">
            {servicesList.map(item => item)}
          </div>
        </div>
      </Services>
      <Gallery images={images} type="salon" />
      <FindUs>
        <h2>{messages.findUs}</h2>
        <iframe
          title="map"
          frameBorder="0"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBn_QTAsaNNI9gmqF9wcalcUNSXUwziltc&q=${mapsKey}&zoom=18`}>
        </iframe>
      </FindUs>
      <Contact backgroundColor={backgroundColor} primaryColor={primaryColor}>
        <h2>{messages.contactUs}</h2>
        <div className="description">
          {messages.contactUsDesc}
        </div>
        <div className="form">
          <input
            placeholder={messages.event.name}
            type="text"
            value={body.nombre}
            onChange={e => handleSetBody('nombre', e.target.value)}
          />
          <input
            placeholder={messages.event.phone}
            type="tel"
            value={body.numero}
            onChange={e => handleSetBody('numero', e.target.value)}
          />
          <input
            placeholder={messages.event.email}
            type="email"
            value={body.email}
            onChange={e => handleSetBody('email', e.target.value)}
          />
          <div className="couple">
            <input
              placeholder={messages.event.type}
              type="text"
              value={body.tipo}
              onChange={e => handleSetBody('tipo', e.target.value)}
            />
            <input
              placeholder={messages.event.people}
              type="number"
              value={body.cantidad}
              onChange={e => handleSetBody('cantidad', e.target.value)}
            />
          </div>
          <textarea
            placeholder={messages.event.message}
            type="text"
            value={body.mensaje}
            onChange={e => handleSetBody('mensaje', e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={avisoPrivacidadChecked}
                onChange={e => setAvisoPrivacidadChecked(e.target.checked)}
                name="aviso-privacidad-check"
                color="primary"
              />
            }
            label={(
              <div>
                {messages.acceptPrivacy}
                <Link to="/aviso-privacidad" target="_blank">{messages.noticePrivacy}</Link>
              </div>
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsChecked}
                onChange={e => setTermsChecked(e.target.checked)}
                name="terminos-check"
                color="primary"
              />
            }
            label={(
              <div>
                {messages.acceptPrivacyPlural}
                <Link to="/terminos-condiciones" target="_blank">{messages.termAndConditions}</Link>
              </div>
            )}
          />
          <button
            className="submit"
            onClick={handleRequestInformation}
            disabled={disabled}
          >
            {messages.send}
          </button>
        </div>
      </Contact>
      <Footer>
        <img className="logo" src={logo} alt={alt} />
        <div className="social-media">
          <a
            href={facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaceboookIcon color={iconColor} />
          </a>
          <a
            href={instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon color={iconColor} />
          </a>
        </div>
        <Link to={linkNoticePrivacy} className="privacy" target="_blank">
          {messages.noticePrivacy}
        </Link>
      </Footer>
      <Snackbar open={Boolean(successOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successOpen}
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(errorOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorOpen}
        </Alert>
      </Snackbar>
    </Container>
  );
}
