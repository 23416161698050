import styled from 'styled-components';
// import { media } from '../../helper';

export const Container = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border-top: 1px solid #E0E3E5;
  padding: 16px 0 12px;
  display: flex;
  justify-content: space-between;
  color: #272A2D;

  & .room-name {
    font-size: 16px;
    margin-bottom: 4pxpx;
    font-weight: 500;
  }

  & .room-detail {
    color: #525969;
    font-size: 14px;
    margin-bottom: 8px;
  }

  & .price-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & .price-detail .price {
    font-size: 16px;
    margin-bottom: 6px;
    color: #272A2D;
  }

  & .price-detail .label-offer {
    color: #1B5E20;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 8px;
    background-color: #E8F5E9;
    border-radius: 4px;
  }
`;
