import styled from 'styled-components';
// import { media } from '../../helper';

export const SInput = styled.input`
  border: 1px solid ${props => props.error ? 'red' : '#D8D5D5'};
  border-radius: 4px;
  padding: 12px 8px;
  max-width: 100%;
  width: ${props => props.width || '100%'};
  outline: none;
  background: #ffffff;
`;
