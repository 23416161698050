const icon = ({ color = '#000000' }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <title>Shape</title>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Footer" transform="translate(-798.000000, -246.000000)" fill={color} fill-rule="nonzero">
              <g id="facebook-logo" transform="translate(798.000000, 246.000000)">
                  <path d="M11.9693878,0 C5.35885714,0 0,5.35885714 0,11.9693878 C0,18.5799184 5.35885714,23.9387755 11.9693878,23.9387755 C18.5799184,23.9387755 23.9387755,18.5799184 23.9387755,11.9693878 C23.9387755,5.35885714 18.5799184,0 11.9693878,0 Z M16.5357551,6.0955102 L14.8802449,6.0962449 C13.5820408,6.0962449 13.3310204,6.71314286 13.3310204,7.61828571 L13.3310204,9.61420408 L16.4267755,9.61420408 L16.4253061,12.7403265 L13.3312653,12.7403265 L13.3312653,20.7626939 L10.1022857,20.7626939 L10.1022857,12.7403265 L7.40302041,12.7403265 L7.40302041,9.61420408 L10.1022857,9.61420408 L10.1022857,7.30873469 C10.1022857,4.63322449 11.7369796,3.17608163 14.1235102,3.17608163 L16.536,3.1797551 L16.536,6.0955102 L16.5357551,6.0955102 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </svg>
);

export default icon;
