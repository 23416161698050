/**
 * Input
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import Numeral from 'numeral';

import BedIcon from '@material-ui/icons/SingleBed';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import Button from '@material-ui/core/Button';

import { Container } from './styles';
import messages from '../../getMessages';

export default function Input({ room, onRoomSelected }) {
  const withPromo = room.price && room.price < room.basePrice;
  const inited = room.isValidAllDays !== undefined;
  return (
    <Container>
      <div>
        <div className="room-name">{room.name}</div>
        <div className="room-detail">
          {room.double > 0 && (
            <div className="detail-item">
              <BedIcon />
              {`${room.double} ${messages.roomsReservation.double}`}
            </div>
          )}
          {room.individual > 0 && (
            <div className="detail-item">
              <BedIcon />
              {`${room.individual} ${messages.roomsReservation.individual}`}
            </div>
          )}
          {room.kingSize > 0 && (
            <div className="detail-item">
              <BedIcon />
            {`${room.kingSize} ${messages.roomsReservation.kingSize}`}
            </div>
          )}
          {room.queenSize > 0 && (
            <div className="detail-item">
              <BedIcon />
            {`${room.queenSize} ${messages.roomsReservation.queenSize}`}
            </div>
          )}
          {room.adults > 0 && (
            <div className="detail-item">
              <PersonOutlinedIcon />
            {`${room.adults} ${messages.roomsReservation.adults}`}
            </div>
          )}
          {room.children > 0 && (
            <div className="detail-item">
              <ChildCareIcon />
            {`${room.children} ${messages.roomsReservation.children}`}
            </div>
          )}
        </div>
      </div>
      {room.isValidAllDays && room.isValidAvailability && (
        <div className="price-detail">
          <Button variant="outlined" color="secondary" onClick={onRoomSelected}>
            {messages.select}
          </Button>
          {withPromo && <div className="base-price">{Numeral(room.basePrice).format('$ 0,0.00')}</div>}
          <div className="price">{Numeral(room.price).format('$ 0,0.00')}</div>
          {withPromo && <div className="label-offer">{messages.offer}</div>}
        </div>
      )}
      {inited && (!room.isValidAllDays || !room.isValidAvailability) && (
        <div className="price-detail">
          <div className="unavailable">
            {messages.notAvailable}
          </div>
        </div>
      )}
    </Container>
  );
}
