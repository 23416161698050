import styled from 'styled-components';
import { media } from '../../helper';

export const ResponsivePicture = styled.img`
  object-fit: cover;
  object-position: center;
  height: 500px;
  max-height: 90vh;
  margin-top: 76px;
`;

export const Container = styled.article`
  display: flex;
  height: 542px;
  max-height: 90vh;
  border: 2px solid #ffffff;
  margin-top: 86px;

  & .main-pic {
    width: 40%;
  }

  & .main-pic,
  & .secondary-section .pic {
    object-fit: cover;
    object-position: center;
    border: 2px solid #ffffff;
    cursor: pointer;
    transition: opacity 0.25s;
    width: 100%;
    height: 100%;
    max-width: 50%;
  }

  & .main-pic:hover,
  & .secondary-section .pic:hover {
    opacity: 0.7;
  }

  & .secondary-section {
    display: flex;
    flex-wrap: wrap;
  }

  & .secondary-section .pic {
    height: 50%;
    width: 50%;
  }
`;

export const ContainerSalon = styled.article`
  border: 2px solid #ffffff;
  display: flex;
  flex-wrap: wrap;

  & .main-pic {
    width: 50%;
    height: 324px;
  }

  & .main-pic,
  & .pic {
    object-fit: cover;
    object-position: center;
    border: 2px solid #ffffff;
    cursor: pointer;
    transition: opacity 0.25s;
  }

  & .main-pic:hover,
  & .pic:hover {
    opacity: 0.7;
  }

  & .pic {
    height: 280px;
    width: 25%;
  }
`;

export const FullScreenGallery = styled.div`
  position: fixed;
  background-color: #000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 120px 0 80px;
  display: flex;
  z-index: 9;

  & .action {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .action button {
    border: 1px solid #ffffff;
  }

  & img {
    flex-grow: 1;
    object-fit: contain;
    max-width: calc(100% - 320px);
  }

  & .close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  & svg {
    color: #ffffff;
  }

  & .count {
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;
    color: #ffffff;
    text-align: center;
  }

  & .full-screen-gallery {
    width: 100%;
  }

  & .responsive-item {
    display: flex !important;
    justify-content: center;
  }

  ${media.small} {
    padding: 60px 0 80px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;

    & .action {
      display: none;
    }

    & img {
      width: 100%;
      max-width: 100%;
    }

    & .count {
      display: none;
    }
  }
`;
