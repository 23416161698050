import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter as Router, } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Axios from 'axios';
import { get } from 'lodash';

import NotFoundPage from './containers/NotFoundPage';
import MainLayout from './containers/MainLayout';
import Homepage from './containers/Homepage';
import HotelDetail from './containers/HotelDetail';
import Restaurants from './containers/Restaurants';
import Contact from './containers/Contact';
import Covid from './containers/Covid';
import Politics from './containers/Politics';
// import Privacy from './containers/Privacy';
import Reservation from './containers/Reservation';
import EventDetail from './containers/EventDetail';
import FileView from './containers/FileView';
import Comment from './containers/Comment';
// import AppRoute from './components/AppRoute';
import ScrollToTop from './components/ScrollToTop';
import { BASE_URL } from './helper';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Avenir', 'Helvetica Neue', 'Arial'].join(','),
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#D8B93C',
    },
    secondary: {
      main: '#060B5B',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f44336',
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#ff9800',
    },
  },
});

const defaultData =  {
   "places":[
      {
         "id":1,
         "place":"la-paz",
         "roomService":"https://hotelesmaioris.com/restaurantes/room-service/la-paz-espanol.pdf",
         "roomServiceEnglish":"https://hotelesmaioris.com/restaurantes/room-service/la-paz-ingles.pdf",
         "menu":"https://hotelesmaioris.com/restaurantes/menu/menu-la-paz-espanol.pdf",
         "menuEnglish":"https://hotelesmaioris.com/restaurantes/menu/menu-la-paz-ingles.pdf",
         "thumbnail":"https://hotelesmaioris.com/images/paz.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/paz.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/7.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/6.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/1.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/2.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/4.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/3.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/5.jpg\", \"https://hotelesmaioris.com/hoteles/la-paz/8.jpg\"]",
         "banner":null,
         "restaurantThumbnail":"https://hotelesmaioris.com/restaurantes/paz.jpg",
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":2,
         "place":"guadalajara",
         "roomService":"https://hotelesmaioris.com/restaurantes/room-service/guadalajara-espanol.pdf",
         "roomServiceEnglish":"https://hotelesmaioris.com/restaurantes/room-service/guadalajara-ingles.pdf",
         "menu":"https://hotelesmaioris.com/restaurantes/menu/menu-guadalajara-espanol.pdf",
         "menuEnglish":"https://hotelesmaioris.com/restaurantes/menu/menu-guadalajara-ingles.pdf",
         "thumbnail":"https://hotelesmaioris.com/images/guadalajara.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/guadalajara.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/18.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/19.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/2.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/16.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/1.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/3.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/4.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/5.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/6.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/7.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/8.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/9.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/10.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/11.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/12.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/13.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/14.jpg\", \"https://hotelesmaioris.com/hoteles/guadalajara/15.jpg\"]",
         "banner":null,
         "restaurantThumbnail":"https://hotelesmaioris.com/restaurantes/guadalajara.jpg",
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":3,
         "place":"el-diez",
         "roomService":null,
         "roomServiceEnglish":null,
         "menu":null,
         "menuEnglish":null,
         "thumbnail":"https://hotelesmaioris.com/images/diez.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/diez.jpg\", \"https://hotelesmaioris.com/hoteles/el-diez/8.jpg\", \"https://hotelesmaioris.com/hoteles/el-diez/1.jpg\", \"https://hotelesmaioris.com/hoteles/el-diez/2.jpeg\", \"https://hotelesmaioris.com/hoteles/el-diez/3.jpeg\", \"https://hotelesmaioris.com/hoteles/el-diez/6.jpg\", \"https://hotelesmaioris.com/hoteles/el-diez/7.jpg\", \"https://hotelesmaioris.com/hoteles/el-diez/10.jpg\"]",
         "banner":null,
         "restaurantThumbnail":"https://hotelesmaioris.com/restaurantes/diez.jpg",
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":4,
         "place":"navolato",
         "roomService":"https://hotelesmaioris.com/restaurantes/room-service/navolato-espanol.pdf",
         "roomServiceEnglish":"https://hotelesmaioris.com/restaurantes/room-service/navolato-ingles.pdf",
         "menu":null,
         "menuEnglish":null,
         "thumbnail":"https://hotelesmaioris.com/images/navolato.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/navolato.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/1.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/19.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/11.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/4.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/5.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/6.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/7.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/8.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/9.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/10.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/13.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/2.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/14.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/15.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/16.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/17.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/18.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/20.jpg\", \"https://hotelesmaioris.com/hoteles/navolato/21.jpg\"]",
         "banner":null,
         "restaurantThumbnail":null,
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":5,
         "place":"kumate",
         "roomService":"https://hotelesmaioris.com/restaurantes/room-service/culiacan-espanol.pdf",
         "roomServiceEnglish":"https://hotelesmaioris.com/restaurantes/room-service/culiacan-ingles.pdf",
         "menu":"https://hotelesmaioris.com/restaurantes/menu/menu-culiacan-espanol.pdf",
         "menuEnglish":"https://hotelesmaioris.com/restaurantes/menu/menu-culiacan-ingles.pdf",
         "thumbnail":"https://hotelesmaioris.com/images/kumate.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/kumate.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/1.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/25.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/2.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/3.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/4.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/5.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/6.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/7.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/8.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/9.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/10.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/15.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/18.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/20.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/21.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/22.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/23.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/24.jpg\", \"https://hotelesmaioris.com/hoteles/kumate/26.jpg\"]",
         "banner":null,
         "restaurantThumbnail":"https://hotelesmaioris.com/restaurantes/kumate.jpg",
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":6,
         "place":"bellavista",
         "roomService":"https://hotelesmaioris.com/restaurantes/room-service/bellavista-espanol.pdf",
         "roomServiceEnglish":"https://hotelesmaioris.com/restaurantes/room-service/bellavista-ingles.pdf",
         "menu":null,
         "menuEnglish":null,
         "thumbnail":"https://hotelesmaioris.com/images/bellavista.jpg",
         "gallery":"[\"https://hotelesmaioris.com/images/bellavista.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/1.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/4.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/6.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/7.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/8.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/10.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/2.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/11.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/12.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/3.jpg\", \"https://hotelesmaioris.com/hoteles/bellavista/14.jpg\"]",
         "banner":null,
         "restaurantThumbnail":null,
         "type":"hotel",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":7,
         "place":"via-esperanza",
         "roomService":null,
         "roomServiceEnglish":null,
         "menu":null,
         "menuEnglish":null,
         "thumbnail":null,
         "gallery":"[\"https://hotelesmaioris.com/eventos/via-esperanza/1.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/2.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/4.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/5.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/6.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/7.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/8.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/9.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/10.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/11.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/12.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/13.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/14.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/15.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/16.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/17.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/18.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/19.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/20.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/21.jpg\", \"https://hotelesmaioris.com/eventos/via-esperanza/22.jpg\"]",
         "banner":"https://hotelesmaioris.com/eventos/background-viaesperanza.jpg",
         "restaurantThumbnail":null,
         "type":"salon",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":8,
         "place":"via-petra",
         "roomService":null,
         "roomServiceEnglish":null,
         "menu":null,
         "menuEnglish":null,
         "thumbnail":null,
         "gallery":"[\"https://hotelesmaioris.com/eventos/via-petra/1.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/2.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/3.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/4.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/5.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/6.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/7.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/8.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/9.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/10.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/11.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/12.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/13.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/15.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/16.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/17.jpg\", \"https://hotelesmaioris.com/eventos/via-petra/18.jpg\"]",
         "banner":"https://hotelesmaioris.com/eventos/via-petra/14.jpg",
         "restaurantThumbnail":null,
         "type":"salon",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      },
      {
         "id":9,
         "place":"beach-club",
         "roomService":null,
         "roomServiceEnglish":null,
         "menu":null,
         "menuEnglish":null,
         "thumbnail":null,
         "gallery":"[\"https://hotelesmaioris.com/eventos/beach-club/2.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/3.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/4.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/5.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/6.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/7.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/8.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/9.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/10.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/11.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/12.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/13.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/14.jpg\", \"https://hotelesmaioris.com/eventos/beach-club/15.jpg\"]",
         "banner":"https://hotelesmaioris.com/eventos/beach-club/1.jpg",
         "restaurantThumbnail":null,
         "type":"salon",
         "createdAt":"2021-10-12T23:02:41.000Z",
         "updatedAt":"2021-10-12T23:02:41.000Z"
      }
   ],
   "landingConfig":{
      "id":1,
      "home":"https://hotelesmaioris.com/images/banner-background.jpg",
      "promotions":"https://hotelesmaioris.com/images/navolato.jpg",
      "comments":"https://hotelesmaioris.com/images/guadalajara.jpg",
      "covid":"https://hotelesmaioris.com/covid.jpg",
      "restaurants":"https://hotelesmaioris.com/images/restaurantes-background.jpg",
      "gallery":"[\"https://hotelesmaioris.com/covid/1.png\", \"https://hotelesmaioris.com/covid/2.jpg\", \"https://hotelesmaioris.com/covid/3.jpg\", \"https://hotelesmaioris.com/covid/4.png\", \"https://hotelesmaioris.com/covid/5.png\", \"https://hotelesmaioris.com/covid/6.jpg\", \"https://hotelesmaioris.com/covid/7.jpg\", \"https://hotelesmaioris.com/covid/8.png\", \"https://hotelesmaioris.com/covid/9.png\", \"https://hotelesmaioris.com/covid/10.jpg\"]",
      "createdAt":"2021-10-12T23:02:41.000Z",
      "updatedAt":"2021-10-12T23:02:41.000Z"
   }
};

function App() {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('data')) || defaultData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Axios
        .get(`${BASE_URL}/places/landing-data`);

      const _data = (get(response, 'data.data') || null);
      setData(_data);
      localStorage.setItem('data', JSON.stringify(_data));
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Helmet
          titleTemplate="Hoteles Maioris - %s"
          defaultTitle="Hoteles Maioris"
        >
          <meta
            name="description"
            content="Hoteles Maioris - diseñados bajo un estilo moderno, ejecutivo, eficiente y distintivo"
          />
        </Helmet>
        <Router>
          <React.Fragment>
            <ScrollToTop />
            <Switch>
              <Route
                exact
                path="/"
                layout={MainLayout}
                component={() => <Homepage data={data} />}
              />
              <Route
                exact
                path="/la-paz"
                layout={MainLayout}
                component={() => <HotelDetail hotel="la-paz" data={data} />}
              />
              <Route
                exact
                path="/guadalajara"
                layout={MainLayout}
                component={() => <HotelDetail hotel="guadalajara" data={data} />}
              />
              <Route
                exact
                path="/el-diez"
                layout={MainLayout}
                component={() => <HotelDetail hotel="el-diez" data={data} />}
              />
              <Route
                exact
                path="/navolato"
                layout={MainLayout}
                component={() => <HotelDetail hotel="navolato" data={data} />}
              />
              <Route
                exact
                path="/kumate"
                layout={MainLayout}
                component={() => <HotelDetail hotel="kumate" data={data} />}
              />
              <Route
                exact
                path="/bellavista"
                layout={MainLayout}
                component={() => <HotelDetail hotel="bellavista" data={data} />}
              />
              <Route
                exact
                path="/restaurantes"
                layout={MainLayout}
                component={() => <Restaurants data={data} />}
              />
              <Route
                exact
                path="/contacto"
                layout={MainLayout}
                component={() => <Contact data={data} />}
              />
              <Route
                exact
                path="/via-esperanza"
                layout={MainLayout}
                component={() => <EventDetail salon="via-esperanza" data={data} />}
              />
              <Route
                exact
                path="/via-petra"
                layout={MainLayout}
                component={() => <EventDetail salon="via-petra" data={data} />}
              />
              <Route
                exact
                path="/maioris-beach-club"
                layout={MainLayout}
                component={() => <EventDetail salon="maioris-beach-club" data={data} />}
              />
              <Route
                exact
                path="/covid-19"
                layout={MainLayout}
                component={() => <Covid data={data} />}
              />
              <Route
                exact
                path="/politicas"
                layout={MainLayout}
                component={() => <Politics data={data} />}
              />
              <Route
                exact
                path="/reservar"
                layout={MainLayout}
                component={(props) => <Reservation data={data} {...props} />}
              />
              <Route
                exact
                path="/menu/guadalajara"
                layout={MainLayout}
                component={() => <FileView view="menu-guadalajara" data={data} />}
              />
              <Route
                exact
                path="/menu/culiacan"
                layout={MainLayout}
                component={() => <FileView view="menu-culiacan" data={data} />}
              />
              <Route
                exact
                path="/menu/la-paz"
                layout={MainLayout}
                component={() => <FileView view="menu-la-paz" data={data} />}
              />
              <Route
                exact
                path="/room-service/bellavista"
                layout={MainLayout}
                component={() => <FileView view="room-service-bellavista" data={data} />}
              />
              <Route
                exact
                path="/room-service/culiacan"
                layout={MainLayout}
                component={() => <FileView view="room-service-culiacan" data={data} />}
              />
              <Route
                exact
                path="/room-service/guadalajara"
                layout={MainLayout}
                component={() => <FileView view="room-service-guadalajara" data={data} />}
              />
              <Route
                exact
                path="/room-service/la-paz"
                layout={MainLayout}
                component={() => <FileView view="room-service-la-paz" data={data} />}
              />
              <Route
                exact
                path="/room-service/navolato"
                layout={MainLayout}
                component={() => <FileView view="room-service-navolato" data={data} />}
              />
              <Route
                exact
                path="/aviso-privacidad"
                layout={MainLayout}
                component={() => <FileView view="aviso-privacidad" data={data} />}
              />
              <Route
                exact
                path="/aviso-legal"
                layout={MainLayout}
                component={() => <FileView view="aviso-legal" data={data} />}
              />
              <Route
                exact
                path="/terminos-condiciones"
                layout={MainLayout}
                component={() => <FileView view="terminos-condiciones" data={data} />}
              />
              <Route
                exact
                path="/comentario"
                layout={MainLayout}
                component={() => <Comment data={data} />}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
