import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  & h1 {
    text-align: center;
    font-size: 40px;
    padding: 48px 24px 28px;
    margin: 0 auto;
    max-width: 1328px;
  }

  & .description {
    padding: 0 24px;
    text-align: center;
    font-size: 24px;
    margin: 0 auto 80px;
    max-width: 1328px;
  }

  & .restaurants-list {
    padding: 0 24px;
    margin: 0 auto;
    max-width: 1328px;
  }

  ${media.small} {
    & h1 {
      font-size: 32px;
      padding: 40px 24px 24px;
    }

    & .description {
      font-size: 18px;
      text-align: justify;
      margin: 0 auto 32px;
    }
  }
`;

export const Item = styled.div`
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 100%;
  min-height: 216px;
  display: flex;
  margin-bottom: 48px;

  & > img {
    width: 224px;
    object-fit: cover;
  }

  & .item-content {
    padding: 24px 32px;
  }

  & .item-content h4 {
    font-size: 21px;
    margin-bottom: 12px;
  }

  & .item-content .item-detail {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 24px;
  }

  & .item-content .item-detail img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  & .item-content .link-menu {
    font-weight: bold;
    color: #242E4D;
    font-size: 21px;
  }

  ${media.small} {
    flex-direction: column;

    & > img {
      width: 100%;
      height: 240px;
    }

    & .item-content .item-detail {
      align-items: flex-start;
    }

    & .item-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
    }

    & .item-content .link-menu {
      align-self: flex-end;
      font-size: 18px;
    }
  }
`;

export const Banner = styled.article`
  min-height: 560px;
  background-color: #5c3d20;
  padding: 0 24px 40px;
  background-repeat: no-repeat;
  background-image: url(${props => props.background});
  background-size: cover;
  position: relative;
  background-position: center;

  & .layer {
    background-color: #000000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .over-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  & .content {
    max-width: 1328px;
    margin: 0 auto;
    padding: 0 24px;
  }

  & .logo-section {
    padding: 20px 12px;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
  }

  & .logo-section img {
    height: 40px;
  }

  & nav {
    display: flex;
    align-items: center;
  }

  & nav a {
    text-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    color: #FFFFFF;
    font-size: 16px;
    transition: opacity 0.25s;
  }

  & nav a:hover {
    opacity: 0.9;
  }

  & nav a:not(:first-child) {
    margin-left: 48px;
  }

  & .language {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-left: 24px;
    flex-shrink: 0;
    text-transform: uppercase;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }

  & .top-section svg {
    display: none;
    color: #ffffff;
  }

  ${media.medium} {
    & nav {
      display: none;
    }

    & .top-section {
      padding: 0 16px;
    }

    & .top-section svg {
      display: block;
    }

    & .reservation {
      justify-content: center;
    }
  }
`;


export const DrawerContainer = styled.div`
  min-width: 280px;
  padding: 32px 24px;

  & > img {
    width: 160px;
    margin-bottom: 40px;
  }

  & nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & nav a {
    margin-bottom: 32px;
    color: #000000;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }
`;
