import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import Select from '../../components/Select';

import messages from '../../getMessages';
import { BASE_URL } from '../../helper';

import {
  Container,
} from './styles';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialBody = {
  hotel: null,
  fecha: '',
  nombre: '',
  comentario: '',
};

const options = [
  {
    value: 'la-paz',
    label: 'Hotel Maioris La Paz',
  },
  {
    value: 'guadalajara',
    label: 'Hotel Maioris Guadalajara',
  },
  {
    value: 'el-diez',
    label: 'Hotel Maioris El Diez',
  },
  {
    value: 'navolato',
    label: 'Hotel Maioris Navolato',
  },
  {
    value: 'kumate',
    label: 'Hotel Maioris Jesús Kumate',
  },
  {
    value: 'bellavista',
    label: 'Hotel Maioris Bellavista',
  },
];

const curr = new Date();
curr.setDate(curr.getDate() + 3);
const defaultDate = curr.toISOString().substr(0,10);

export default function Reservation({ children, history, data }) {
  const isResponsive = useMediaQuery('@media only screen and (max-width: 992px)');

  const [body, setBody] = useState({ ...initialBody });
  const [successOpen, setSuccessOpen] = useState('');
  const [errorOpen, setErrorOpen] = useState('');
  const [avisoPrivacidadChecked, setAvisoPrivacidadChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (isResponsive) {
      setBody({
        ...initialBody,
        fecha: defaultDate,
      });
    }
  }, [isResponsive]);

  const handleSendComment = async () => {
    try {
      if (
        !body.hotel ||
        !body.hotel.value ||
        !body.fecha ||
        !body.nombre ||
        !body.comentario
      ) {
        setErrorOpen(messages.incomplete);
        return;
      }
      const response = await Axios
        .post(`${BASE_URL}/maioris/send-comment`,
          {
            ...body,
            hotel: body.hotel.label,
          }
        );

      const success = get(response, 'data.data.success', false);
      if (!success) {
        setErrorOpen(messages.event.error);
        return;
      }

      setBody({ ...initialBody });
      setSuccessOpen(messages.comments.commentSent);
    } catch (e) {
      console.error(e);
      setErrorOpen(messages.event.error);
    }
  };

  const handleSetBody = (key, value) =>
    setBody(_body => ({
      ..._body,
      [key]: value,
    }));

  const handleClose = () => {
    setSuccessOpen('');
    setErrorOpen('');
  };

  const disabled = !avisoPrivacidadChecked || !termsChecked;

  return (
    <Container>
      <Header />
        <div className="content-reservation">
          <h1>{messages.comments.comments}</h1>
          <div className="brief">{messages.comments.brief}</div>
          <div className="form">
            <Input
              placeholder={messages.comments.name}
              value={body.nombre}
              onChange={e => handleSetBody('nombre', e.target.value)}
            />
            <div className="small-label">{messages.comments.date}</div>
            <Input
              placeholder={messages.comments.date}
              type="date"
              value={body.fecha}
              onChange={e => handleSetBody('fecha', e.target.value)}
            />
            <Select
              placeholder={messages.comments.hotel}
              options={options}
              value={body.hotel}
              onChange={value => handleSetBody('hotel', value)}
            />
            <textarea
              placeholder={messages.comments.comment}
              type="text"
              value={body.comentario}
              onChange={e => handleSetBody('comentario', e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={avisoPrivacidadChecked}
                  onChange={e => setAvisoPrivacidadChecked(e.target.checked)}
                  name="aviso-privacidad-check"
                  color="primary"
                />
              }
              label={(
                <div>
                  {messages.acceptPrivacy}
                  <Link to="/aviso-privacidad" target="_blank">{messages.noticePrivacy}</Link>
                </div>
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={e => setTermsChecked(e.target.checked)}
                  name="terminos-check"
                  color="primary"
                />
              }
              label={(
                <div>
                  {messages.acceptPrivacyPlural}
                  <Link to="/terminos-condiciones" target="_blank">{messages.termAndConditions}</Link>
                </div>
              )}
            />
            <button
              onClick={handleSendComment}
              className="send"
              disabled={disabled}
            >
              {messages.comments.send}
            </button>
          </div>
          <h2>{messages.comments.moreHotels}</h2>
          <div className="card-list">
            <Card hotel="la-paz" data={data.places} />
            <Card hotel="guadalajara" data={data.places} />
            <Card hotel="el-diez" data={data.places} />
            <Card hotel="navolato" data={data.places} />
            <Card hotel="kumate" data={data.places} />
            <Card hotel="bellavista" data={data.places} />
          </div>
        </div>
      <Footer />
      <Snackbar open={Boolean(successOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successOpen}
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(errorOpen)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorOpen}
        </Alert>
      </Snackbar>
    </Container>
  );
}
