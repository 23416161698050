import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & h1 {
    text-align: center;
    font-size: 40px;
    padding: 48px 24px 28px;
    margin: 0 auto;
    max-width: 1328px;
  }

  & .description {
    padding: 0 24px;
    text-align: center;
    font-size: 24px;
    margin: 0 auto 80px;
    max-width: 1328px;
  }

  & .restaurants-list {
    padding: 0 24px;
    margin: 0 auto;
    max-width: 1328px;
  }

  ${media.small} {
    & h1 {
      font-size: 32px;
      padding: 40px 24px 24px;
    }

    & .description {
      font-size: 18px;
      text-align: justify;
      margin: 0 auto 32px;
    }
  }
`;

export const Item = styled.div`
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 100%;
  min-height: 180px;
  display: flex;
  margin-bottom: 48px;
  color: #000000;

  & a > img {
    width: 220px;
    object-fit: cover;
    height: 100%;
  }

  & .item-content {
    padding: 24px 32px;
  }

  & .item-content h4 {
    font-size: 21px;
    margin-bottom: 12px;
  }

  & .item-content .item-detail {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 12px;
  }

  & .item-content .item-detail img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  & .item-content .item-whatsapp img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    background-color: black;
    border-radius: 50%;
  }

  & .item-content .link-menu {
    font-weight: bold;
    color: #242E4D;
    font-size: 21px;
  }

  ${media.small} {
    flex-direction: column;

    & > a {
      width: 100%;
      height: 240px;
    }

    & a > img {
      width: 100%;
      height: 100%;
    }

    & .item-content .item-detail {
      align-items: flex-start;
    }

    & .item-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
    }

    & .item-content .link-menu {
      align-self: flex-end;
      font-size: 18px;
    }
  }
`;
