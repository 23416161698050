const englishMessages = {
  restaurants: 'Restaurants',
  events: 'Events',
  contact: 'Contact',
  reservation: 'Book now',
  welcome: 'Welcome to Hoteles Maioris',
  welcomeMessage: 'Online booking is easy and safe',
  hotel: 'Hotel',
  arrive: 'Arrival',
  exit: 'Departure',
  adults: 'Adults',
  children: 'Children',
  code: 'Promotion code',
  book: 'Book now',
  ourHotels: 'Our hotels',
  hotelsDescription: 'We are a group of hotels focused on providing the best service and experience to each of our guests, business travelers and families. Designed to live a perfect stay of rest, comfort and hospitality, with access to our delicious restaurant, fully equipped meeting rooms, private and secure parking. More than 20 years of experience support us.',
  seeMore: 'See more',
  ourPromotions: 'Discover our promotions',
  promorionsDesc:' At Hoteles Maioris, pampering yourself comes first',
  seePromotions: 'Check out our best deals',
  restrictions: 'Apply restrictions',
  ourPresence: 'Our presence',
  presenceDesc: 'Maioris Hotels are located in: Culiacán, Navolato, Guadalajara and La Paz.',
  hotels: 'Hotels',
  hotelPolitics: 'Hotel Policies',
  noticePrivacy: 'Privacy Notice',
  noticePrivacyLink: 'https://hotelesmaioris.com/aviso-privacidad-hoteles-maioris.pdf',
  noticeLegal: 'Legal Notice',
  noticeLegalLink: 'https://hotelesmaioris.com/aviso-legal.pdf',
  termConditions: 'Terms and conditions',
  termConditionsLink: 'https://hotelesmaioris.com/terminos-condiciones.pdf',
  termAndConditions: 'Terms and conditions',
  followUs: 'Follow us',
  laPaz: {
    brief1: 'Located on the Transpeninsular Highway, only 4 km from the Manuel Marquez de Leon International Airport and 9 km from the city downtown, Hotel Maioris La Paz is an excellent option for families and business guests looking for affordable accommodation in La Paz, Baja California Sur, Mexico. Our hotel, built in 2009, has become in such a short period of time in one of the best and most popular hotels in La Paz, Mexico, not only for its convenient location near the airport but also for the undeniable quality of its attentions and services.',
    brief2: 'Modern decoration and family friendly atmosphere will make your stay in Hotel Maioris La Paz a comfortable experience from beginning to end. The facilities offer everything you need and then some, to enjoy your stay in the state capital to the fullest.',
    whatToDo: 'What to do in La Paz?',
    shortWhatToDo: 'La Bahía de La Paz is renowned for the tranquility of its waters, the spectacularity of its sunsets and the wide range of turquoise tones that fade on its beaches. The city is characterized by the tranquility and calm with which it is lived, a place that boasts its name, "La Paz", and that has become a world-renowned destination for rest and retirement. The hospitality and warmth of its people make this port a comfortable and peaceful place.',
    fullDescription: [
      'La Paz is the capital of the state of Baja California Sur, a beautiful place full of contrasts in its landscapes, the desert naturally decorated in cacti and its beautiful beaches separate the land from the sea with a color bias that impacts the pupil, creating a postcard panorama that you will enjoy. ',
      'La Bahía de La Paz is renowned for the tranquility of its waters, the spectacularity of its sunsets and the wide range of turquoise tones that fade on its beaches. The city is characterized by the tranquility and calm with which it is lived, a place that boasts its name, "La Paz", and that has become a world-renowned destination for rest and retirement. The hospitality and warmth of its people make this port a comfortable and peaceful place. ',
      'Because it is the state with the most coastal territory in the country, 2,230 kilometers of coastline, activities related to the sea, such as sport fishing, diving, kayaking and snorkeling, along with other water sports, are predominant and great attractions in the area. that you cannot miss. In addition to its paradisiacal beaches with calm waters and cream-colored sand, you can take advantage of it to rest or to entertain yourself. ',
      'The historical and cultural wealth that this city has are elements that greatly multiply the reasons to visit it; the Malecón, where Hernán Cortés arrived in 1535, is one of the favorite places of tourists and locals, presenting the bay as a majestic and harmonious setting surrounded by fun, restaurants, beautiful landscapes and comfort. ',
      'The city has all the services to satisfy the needs of the most demanding tourist: flights, hotels, car rentals, activities and nightlife are elements that bring together the qualities for your stay in La Paz, whether for business or pleasure, be ideal. ',
    ],
  },
  guadalajara: {
    brief1: 'Set in Guadalajara, 17 from Guadalajara International Airport, 11 km from Expiatory Temple, Hotel Maioris offers accommodation with a restaurant, free private parking and a bar.',
    brief2: 'It has 24-hour reception, room service and free WiFi throughout. The property is 17 km from Jalisco Stadium and 28 km from Guadalajara Zoo.',
    whatToDo: 'What to do in Guadalajara?',
    shortWhatToDo: 'Guadalajara is the Pearl of the West of Mexico, recognized worldwide for being the capital of Tequila and Mariachi. This city, capital of the state of Jalisco, has an immense historical and cultural heritage, as well as an extensive number of monumental buildings recognized for their spectacular architecture, mainly from the Neoclassical and Baroque trends.',
    fullDescription: [
      'Guadalajara is the Pearl of the West of Mexico, recognized worldwide for being the capital of Tequila and Mariachi. This city, capital of the state of Jalisco, has an immense historical and cultural heritage, as well as an extensive number of monumental buildings recognized for their spectacular architecture, mainly from the Neoclassical and Baroque trends. ',
      'Guadalajara is the second largest city in the country and an important artery of the Mexican economy, due to the amount of economic activities that take place in it, among which tourism stands out, for offering a wide range of options and activities to the visitors, with the best hotels, cultural and recreational activities, as well as its intense nightlife and entertainment. ',
      'The invariable number of places to visit in Guadalajara give it a unique wealth, such as its museums, galleries, spas, parks, nature reserves, archaeological sites, sculptures, fountains, roundabouts, monuments, murals, squares, gardens and walkways, which cultivate and captivate those who visit the capital of Guadalajara. ',
      'The wealth that Guadalajara offers is tradition and modernity. Ambassador of the image of Mexico, it is one of the most representative cities in the country and a historical reference, but it is also a developed city. Its efficient and up-to-date public services guarantee a comfortable stay, as well as its modern transportation, make possible an agile movement in the Jalisco capital. ',
      'Another of the important attractions of Guadalajara is the proximity it has with other important points of the geography of Jalisco, such as Tonalá, Zapopan, and especially Puerto Vallarta and Tequila, these last two transcendental destinations of tourist attraction.',
      'The gastronomy of the city stands out for its drowned cakes, for the traditional tequila and for its beef birria, delicious motifs that increase "the flavor" of this cosmopolitan city full of attractions.',
    ],
  },
  elDiez: {
    brief1: 'Hotel Maioris El Diez Culiacán offers comfortable rooms in Culiacán, 10 km from Fórum Culiacán shopping center and 11 km from Banorte Stadium.',
    brief2: 'The closest airport is Culiacán International Airport, located 3 km from the hotel.',
    whatToDo: 'What to do in Culiacán?',
    shortWhatToDo: 'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
    fullDescription: [
      'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
      'In Culiacán you will find iconic sites that dazzle for their architecture, such as the Cathedral of Nuestra Señora del Rosario, an imposing building from the 19th century located next to the Plaza Álvaro Obregón; the Dancing Fountains, with their music, colors and movements, amaze their viewers, while other spaces dedicated to the preservation of nature, such as the Crocodile Farm, teach their visitors about the care of the local fauna. ',
      'When visiting Culiacán, be sure to take a sightseeing tour aboard the Turibús. Available from Tuesday to Sunday, this tour is guided with the explanation of a well-trained guide. Among the sites you will visit are the Botanical Garden, the Casa Museo del Chino Billetero, the Zoological Park and the temple of Nuestra Señora De Guadalupe. ',
      'Parque las Riberas is one of the main recreational and leisure parks in the city. Here there are shootings, a bike path, Eurobongie, a reptile area and all kinds of attractions for the whole family, available from Tuesday to Sunday. ',
      'Culiacán has beautiful beaches such as Conchal, Médano Blanco, Vigía, El Robalar, Las Arenitas and the beaches of Ponce are very popular with surfers and naturalists who like to observe birds and marine fauna.',
      'Do not miss out on visiting the EME park, it is 52 hectares of fun and healthy training. This water park offers tennis courts, basketball, pools, slides, gardens and a whole range of attractions for the whole family, which has made it one of the favorite places for locals. ',
    ],
  },
  navolato: {
    brief1: 'Culiacán is located 13 km from Hotel Maioris Navolato. The nearest airport is Culiacán International Airport, 15 km from the hotel. ',
    brief2: 'The establishment has a 24-hour reception, room service and free WiFi. The hotel has a garden and an outdoor swimming pool. ',
    whatToDo: 'What to do in Navolato?',
    shortWhatToDo: 'Navolato is the youngest municipality in the Mexican state of Sinaloa; its name, which means Where There are Tunas and Nopales, comes from the May language "Navo", from the Aztec and from the Cahita-Nahuatl. For many years, the most important economic activity in Navolato was agriculture, where the main crop is sugarcane. Today, due to the closure of the sugar mills, it has been in decline, giving impetus to the tourism industry. ',
    fullDescription: [
      'Navolato is the youngest municipality in Sinaloa, which means Where There Are Tunas and Nopales, it comes from the May language "Navo", from the Aztec and from the Achaean-Nahuatl. For many years, the most important economic activity in Navolato was agriculture, where the main crop is sugarcane. Today, due to the closure of the sugar mills, it has been in decline, giving impetus to the tourism industry. ',
      'With an average annual temperature of 24º C and beautiful coasts of calm waters, Navolato is emerging as a special destination for beach tourism. The picturesque Bay of Altata, 65km from the capital of Sinaloa, and its beach are areas dedicated to shrimp and oyster fishing but it is also a protected natural area as it is home to mangroves, reefs and incredible native fauna. ',
      'The beaches of Navolato are perfect for a day of sun and sand. Playa El Tambor is a favorite for camping and enjoying fun and exciting water activities; Towns such as El Tetuán are privileged due to their location on the Sinaloan coast. In addition to beaches there are architectural attractions, such as: ',
      'The Parish of San Francisco de Asís',
      'Neoclassical style construction that stands out for its portico with semicircular arches. The day of San Francisco is celebrated in a big way with musical events, dances and of course a mass celebrated in the parish. ',
      'Chapel of San José',
      'Located on the slopes of the so-called hill of the ice cream shop. It is an eclectic style construction that inside protects the image of San José, Santa Teresa de Ávila and the Immaculate Conception, the latter two brought from Europe. ',
      'Vicente Guerrero Square',
      'A nice kiosk adorns the central square of Navolato, Sinaloa. Located in front of the San Francisco de Asís parish, it is the favorite place for locals who enjoy selling handicrafts and typical gastronomy here. ',
    ],
  },
  kumate: {
    brief1: 'Hotel Maioris, Jesús Kumate branch, is located in Culiacán, 8 km from Fórum Culiacán shopping center, and offers air-conditioned rooms and a fresh and pleasant garden.',
    brief2: 'The Banorte stadium is 9 km away. The nearest airport is Culiacán International Airport, located 2 km from the hotel. ',
    whatToDo: 'What to do in Culiacán?',
    shortWhatToDo: 'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
    fullDescription: [
      'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
      'In Culiacán you will find iconic sites that dazzle for their architecture, such as the Cathedral of Nuestra Señora del Rosario, an imposing building from the 19th century located next to the Plaza Álvaro Obregón; the Dancing Fountains, with their music, colors and movements, amaze their viewers, while other spaces dedicated to the preservation of nature, such as the Crocodile Farm, teach their visitors about the care of the local fauna. ',
      'When visiting Culiacán, be sure to take a sightseeing tour aboard the Turibús. Available from Tuesday to Sunday, this tour is guided with the explanation of a well-trained guide. Among the sites you will visit are the Botanical Garden, the Casa Museo del Chino Billetero, the Zoological Park and the temple of Nuestra Señora De Guadalupe. ',
      'Parque las Riberas is one of the main recreational and leisure parks in the city. Here there are shootings, a bike path, Eurobongie, a reptile area and all kinds of attractions for the whole family, available from Tuesday to Sunday. ',
      'Culiacán has beautiful beaches such as Conchal, Médano Blanco, Vigía, El Robalar, Las Arenitas and the beaches of Ponce are very popular with surfers and naturalists who like to observe birds and marine fauna.',
      'Do not miss out on visiting the EME park, it is 52 hectares of fun and healthy training. This water park offers tennis courts, basketball, pools, slides, gardens and a whole range of attractions for the whole family, which has made it one of the favorite places for locals. ',
    ],
  },
  bellavista: {
    brief1: 'Hotel Maioris Bellavista offers comfortable luxury rooms fully equipped under an executive concept as well as having a pool to make your stay as pleasant as possible.',
    brief2: 'The Forum Culiacán shopping center is 9 km from Hotel Maioris Bellavista. The nearest airport is Culiacán International Airport, 11 km from the accommodation. ',
    whatToDo: 'What to do in Culiacán?',
    shortWhatToDo: 'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
    fullDescription: [
      'The capital city of the state offers a whole series of possibilities of entertainment, fun and recreation to the tourist who visits it. Located in the center of Sinaloa, Culiacán is a paradise for lovers of rural tourism and for those who like extreme sports, mountaineering, rappelling and all kinds of outdoor activities. ',
      'In Culiacán you will find iconic sites that dazzle for their architecture, such as the Cathedral of Nuestra Señora del Rosario, an imposing building from the 19th century located next to the Plaza Álvaro Obregón; the Dancing Fountains, with their music, colors and movements, amaze their viewers, while other spaces dedicated to the preservation of nature, such as the Crocodile Farm, teach their visitors about the care of the local fauna. ',
      'When visiting Culiacán, be sure to take a sightseeing tour aboard the Turibús. Available from Tuesday to Sunday, this tour is guided with the explanation of a well-trained guide. Among the sites you will visit are the Botanical Garden, the Casa Museo del Chino Billetero, the Zoological Park and the temple of Nuestra Señora De Guadalupe. ',
      'Parque las Riberas is one of the main recreational and leisure parks in the city. Here there are shootings, a bike path, Eurobongie, a reptile area and all kinds of attractions for the whole family, available from Tuesday to Sunday. ',
      'Culiacán has beautiful beaches such as Conchal, Médano Blanco, Vigía, El Robalar, Las Arenitas and the beaches of Ponce are very popular with surfers and naturalists who like to observe birds and marine fauna.',
      'Do not miss out on visiting the EME park, it is 52 hectares of fun and healthy training. This water park offers tennis courts, basketball, pools, slides, gardens and a whole range of attractions for the whole family, which has made it one of the favorite places for locals. ',
    ],
  },
  services: {
    services: 'Services',
    rooms: 'Comfortable rooms',
    boardRooms: 'Boardroom',
    wheelChair: 'Wheelchair access',
    fireSafety: 'Fire extinguishers',
    bilingual: 'Bilingual staff',
    security24: '24 hour security',
    hygiene: 'Personal hygiene amenities',
    computer: 'Computer access in the lobby',
    frigobar: 'Mini fridge',
    alberca: 'Pool',
    wifi: 'WiFi',
    restaurant: 'Restaurant',
    estacionamiento: 'Parking',
    extinguidor: 'Fire extinguishers',
    sillaRuedas: 'Wheelchair access',
    salaJuntas: 'Boardroom',
    bilingue: 'Bilingual staff',
    computadora: 'Computer access in the lobby',
    aire: 'Air conditioning',
    servicio: 'Room service',
    tv: 'cable TV',
    jardin: 'Gardens',
  },
  rooms: {
    rooms: 'Rooms',
    adults: 'Adults',
    kingSize: 'King size bed',
    children: 'Children (under 12 years old)',
    matrimonialSize: 'Double beds',
    individual: 'Individual beds',
    queenSize: 'Queen size beds',
  },
  location: 'Location',
  knowMoreDestinations: 'Know more destinations',
  moreServices: 'More services',
  covid: {
     protocols: 'Safety and hygiene protocols',
     description: 'The health and safety of our guests and coworkers are our priority. The use of face masks are mandatory and required to be worn in all public areas.',
     hands: 'Wash your hands as necessary',
     mask: 'Mandatory use of face mask in all public areas',
     distance: 'Keep 1.5 meter distance from people around you',
     areas: 'Constant sanitation of areas in use',
     checkin: 'Sanitary filter when checking in',
     luggage: 'Disinfection of suitcases',
     shoes: 'Shoe disinfection',
     measures: 'Know our sanitary measures',
   },
   watch: 'See more',
   restaurant: {
     laPaz: 'La Paz restaurant',
     guadalajara: 'Guadalajara restaurant',
     kumate: 'Jesús Kumate restaurant',
     elDiez: 'El Diez restaurant',
     description: 'In Signus Restaurants we offer a wide variety of dishes of excellent quality where you can find juicy steaks, delicious seafood, regional and international dishes and traditional breakfasts; All this in a comfortable and pleasant environment. Enjoy our home-flavored cuisine, which we make within our hearts, especially for you.',
     seeMenu: 'Download menu',
     menus: {
       guadalajara: {
         title: 'Guadalajara menu',
         link: 'https://hotelesmaioris.com/restaurantes/menu/menu-guadalajara-ingles.pdf',
       },
       culiacan: {
         title: 'Culiacán menu',
         link: 'https://hotelesmaioris.com/restaurantes/menu/menu-culiacan-ingles.pdf',
       },
       'la-paz': {
         title: 'La Paz menu',
         link: 'https://hotelesmaioris.com/restaurantes/menu/menu-la-paz-ingles.pdf',
       },
     },
     'room-service': {
       bellavista: {
         title: 'Bellavista room service',
         // link: 'http://localhost:3000/restaurantes/room-service/bellavista-ingles.pdf',
         link: 'https://hotelesmaioris.com/restaurantes/room-service/bellavista-ingles.pdf',
       },
       culiacan: {
         title: 'Culiacán room service',
         link: 'https://hotelesmaioris.com/restaurantes/room-service/culiacan-ingles.pdf',
       },
       guadalajara: {
         title: 'Guadalajara room service',
         link: 'https://hotelesmaioris.com/restaurantes/room-service/guadalajara-ingles.pdf',
       },
       'la-paz': {
         title: 'La Paz room service',
         link: 'https://hotelesmaioris.com/restaurantes/room-service/la-paz-ingles.pdf',
       },
       navolato: {
         title: 'Navolato room service',
         link: 'https://hotelesmaioris.com/restaurantes/room-service/navolato-ingles.pdf',
       },
     },
   },
   event: {
    success: 'Request sent!',
    error: 'An error occurred, please try again',
    viaEsperanzaDesc1: 'At Vía Esperanza we have everything to bring your celebration to life, surrounded by gardens where you can create different atmospheres. We celebrate that special day with elegance and style.',
    viaEsperanzaDesc2: 'Our ballrooms and garden guarantees the perfect venue, with a quality service and specialized staff to coordinate and create corporate and social events at the best location in the city',
    viaPetraDesc1: 'We are a team specialized in coordinating and in making social and business events, offering an all around service that would make easy for the client to organize their event. The facilities in our venue are perfect for your special day and it can be transform as you like too.',
    viaPetraDesc2: 'Via Petra offers you the possibility of staying beside the venue. Located next to Hotel Maioris Bellavista, it gives your guests the possibility to rest in broad, comfortable, modern and safe rooms at the end of the event.',
    beachClubDesc1: 'We will accompany you until your big day. With a beautiful garden and a palapa equipped to make your event a beautiful and unforgettable experience. Live the best day of your life with us, schedule your appointment and know everything we have prepared for you.',
    beachClubDesc2: 'Located next to Hotel Maioris La Paz, so your guests can enjoy the event and stay in the same place.',
    name: 'Name',
    phone: 'Cell phone number',
    email: 'Email',
    type: 'Event type',
    people: 'Number of people',
    message: 'Message',
  },
  eventServices: {
    decorationsAdvisor: 'Deco advisor',
    trainedStaff: 'Professional staff',
    exclusiveBanquet: 'Exclusive Banquet',
    lightsAndSound: 'Sound and light show',
    furnitureRental: 'Furniture rental',
    valetParking: 'Valet parking / parking',
    surveillance: 'Surveillance',
    innovativeDesign: 'Innovative design',
    eventCoordinator: 'Event Coordinator',
    garden: 'Palapa and pool',
    parking: 'Private parking',
    airConditioning: 'Rooms with A/C',
    gardenOnly: 'Garden',
    valet: 'Valet Parking',
  },
  contactUs: 'Contact us',
  contactUsDesc: 'If you want more information leave us your information and we will contact you as soon as possible',
  send: 'Send',
  contactDesc: 'For Hoteles Maioris your doubts, comments and suggestions are always very important. Easily contact our hotels using the following information',
  reservations: {
    reservation: 'Reservation',
    book: 'Book',
    night: 'night',
    hotel: 'Hotel',
    arrival: 'Arrival',
    departure: 'Departure',
    adults: 'Adults',
    children: 'Children',
    promotionCode: 'Promotion code',
    fullName: 'Full name',
    phone: 'Cell phone number',
    email: 'Email',
    room: 'Room type',
  },
  incomplete: 'Information incomplete',
  reservationComplete: 'Request sent!',
  politics: {
    politics: 'Privacy Policies',
    paragraph1: 'Protecting your privacy is our highest priority to ensure the confidentiality of your personal information.',
    paragraph2: 'We securely store all information on our site. This includes personal information such as your first and last name, personal and business address, telephone number, postal address, and email address.',
    paragraph3: 'The information you provide us is not shared with unauthorized third parties. We may share only the minimum necessary, to companies related to the online payment service.',
    reservationPolitics: 'Reservation Policies',
    checkin: 'Check-in and Check-out',
    checkinDesc: 'The check-in time is at 3:00 p.m. and must be vacated at 12:00 p.m. the next day. After that time, the Hotel may make an extra charge.',
    guarantee: 'Guarantee policy',
    guaranteeDesc: 'All reservations must be guaranteed by a payment in any of the available methods, on behalf of the Guest. When a reservation is not guaranteed, the Hotel will not be obligated to respect the reservation.',
    cancel: 'Cancellation policy',
    cancelDesc: 'Any change or cancellation to your reservation must be requested by dialing directly at the reception of the destination at least 72 hours in advance of the arrival date at the hotel to avoid generating charges. After these 72 hours, the charge of one night per reserved room will be made.',
    notArrive: 'No arrival policy',
    notArriveDesc: 'In case of No Arrival, one night will be charged.',
    extra: 'Extra person policy',
    extraDesc: 'An extra charge is applied for each extra person in the room. See rate when checking availability',
    children: 'Policy for minors',
    childrenDesc: 'A child is considered someone under the age of 11 years old. Someone from 12 years old and above will be charged as an adult. The room with 2 adults can be shared with 2 children under 12 years old without additional charge.',
    pets: 'Pet policy',
    petsDes: 'Unfortunately, pets are not allowed.',
    overSell: 'Overselling policy',
    overSellDesc: 'In the situation that the Hotel cannot provide the contracted hosting service with a reservation from the website, we will provide adequate hotel alternatives to supply the contracted service, which need to be at least equal to the originally contracted services. Transportation will be offered free to the new Hotel. We will make at least one call to the guest prior to their stay on the phone provided commenting the situation.',
    specialPrice: 'Corporate and Group rates',
    specialPriceDesc: 'For groups and companies, please contact the reception of the hotel where you want to stay directly, to be able to offer a preferential rate.',
  },
  privacy: {
    title: 'Aviso de privacidad',
    intro: 'El presente aviso de privacidad se integra a partir de lo señalado en los artículos 8, 15, 16, 18, 36, entre otros de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP).',
    paragraphs: [
      {
        title: 'Aviso de privacidad integral',

      }
    ],
  },
  findUs: 'Find us',
  comments: {
    title: 'Have you stayed with us?',
    description: 'Leave us your comments to improve our service',
    action: 'Leave comments',
    moreHotels: 'Meet our destinations',
    comments: 'Comments',
    brief: 'Leave us your comments about the service we provide you during your stay.',
    name: 'Your name',
    date: 'Date',
    hotel: 'Hotel where you stayed',
    comment: 'Your comments and / or suggestions',
    send: 'Send',
    commentSent: 'Comments sent!',
  },
  loadingMenu: 'Loading menu...',
  errorPdf: 'An error ocurred, reload the site',
  and: 'and',
  acceptPrivacy: 'I have read, understand and accept the ',
  acceptPrivacyPlural: 'I have read, understand and accept the ',
  badEmail: 'Enter a valid email',
  badPhone: 'Enter a valid phone number',
  errorDates: 'Enter valid dates',
  errorPeople: 'Enter a valid number of people',
  steps: {
    select: 'Select hotel',
    dates: 'Dates and rooms',
    personal: 'Personal info',
    selectAnHotel: 'Select one of out hotels',
    selectRooms: 'Select the rooms you need',
  },
  errorRooms: 'Error fetching rooms, please try again',
  errorAvailability: 'Error fetching availability, please try again',
  roomsReservation: {
    double: 'double beds',
    individual: 'individual beds',
    kingSize: 'king size beds',
    queenSize: 'queen size beds',
    adults: 'adults',
    children: 'children',
  },
  verifyAvailaility: 'Verify availability',
  select: 'Select',
  offer: 'Special offer',
  notAvailable: 'Not available',
  delete: 'Delete',
  max: 'max',
  totalToPay: 'Total to pay',
  taxesIncluded: 'Taxes and fees included',
  roomLabel: 'rooms',
  nights: 'nights',
  generalInfo: 'Personal info',
  paymentInfo: 'Payment info',
  nameOnCard: 'Name on card',
  cardNumber: 'Card number',
  cardNumberError: 'Invalid card',
  invalidField: 'Invalid value',
  thanksForBooking: 'Thanks for booking',
  jobOpportunities: 'Job opportunities'
};

export default englishMessages;
