import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & h1 {
    padding: 16px 0;
  }

  & > .content {
    padding: 40px 24px;
    max-width: 1328px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .content .pdf-content {
  /* & > .content iframe { */
    height: 70vh;
    max-height: 800px;
    min-height: 400px;
    padding: 0;
    border: none;
    overflow: auto;
    display: flex;
    width: 600px;
    max-width: 100%;
  }

  & .react-pdf__Document {
    max-width: 100%;
  }

  ${media.small} {
    & > .content {
      padding: 16px;
    }
  }
`;
