import styled from 'styled-components';
import { mediaQuerySmall } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & .politics-content {
    padding: 56px 24px 40px;
    margin: 0 auto;
    max-width: 1328px;
  }

  & h1 {
    font-size: 40px;
    margin-bottom: 40px;
  }

  & .block {
    padding: 24px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    margin-bottom: 40px;
  }

  & .block .title {
    margin-bottom: 24px;
    font-size: 21px;
    font-weight: bold;
  }

  & .detail {
    font-size: 16px;
  }

  & .detail:not(:last-child) {
    margin-bottom: 24px;
  }

  & .subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  & .detail-reservation {
    font-size: 16px;
  }

  & .detail-reservation:not(:last-child) {
    margin-bottom: 24px;
  }
`;
