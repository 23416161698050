import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  ${media.small} {
    & .space-between {
      align-items: flex-start;
    }
  }
`;

export const Banner = styled.article`
  min-height: 560px;
  background-color: #5c3d20;
  padding: 0 24px 40px;
  background-repeat: no-repeat;
  background-image: url('${props => props.background}');
  background-size: cover;
  position: relative;
  background-position: center;

  & .layer {
    background-color: #000000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .over-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  & .content {
    max-width: 1328px;
    padding: 0 24px;
    margin: 0 auto;
  }

  & .logo-section {
    padding: 20px 12px;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
  }

  & .logo-section img {
    height: ${props => props.logoHeight || '40px'};
    max-width: 132px;
    object-fit: contain;
  }

  & nav {
    display: flex;
  }

  & nav a {
    text-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    color: #FFFFFF;
    font-size: 16px;
    transition: opacity 0.25s;
  }

  & nav a:hover {
    opacity: 0.9;
  }

  & nav a:not(:first-child) {
    margin-left: 48px;
  }

  & .reservar-button {
    background: ${props => props.color || '#D8B93C'};
    padding: 12px 40px;
    border-radius: 4px;
  }

  & .phone {
    display: flex;
    align-items: center;
  }

  & .phone svg {
    margin-right: 8px;
  }

  ${media.small} {
    & nav {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      padding-top: 16px;
    }

    & .phone {
      margin-top: 24px;
    }
  }
`;

export const EventBrief = styled.article`
  padding: 80px 24px;
  background-color: ${props => props.backgroundColor};
  text-align: center;

  & .content {
    max-width: 1328px;
    margin: 0 auto;
  }

  & h1 {
    margin-bottom: 32px;
    font-size: 40px;
    font-style: italic;
  }

  & .description {
    font-size: 24px;
  }

  ${media.small} {
    & h1 {
      font-size: 32px;
    }

    & .description {
      font-size: 18px;
      text-align: justify;
    }
  }
`;

export const Services = styled.article`
  padding: 80px 24px 0;

  & .content {
    max-width: 1328px;
    margin: 0 auto;
  }

  & h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 24px;
    font-style: italic;
  }

  & .description {
    font-size: 24px;
    text-align: center;
    margin-bottom: 80px;
  }

  & .services-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 920px;
    margin: 0 auto;
  }

  ${media.small} {
    padding: 32px 24px 0;

    & h2 {
      font-size: 32px;
    }

    & .description {
      font-size: 18px;
      text-align: justify;
      margin-bottom: 24px;
    }
  }
`;

export const FindUs = styled.article`
  padding: 80px 0 40px;

  & .content {
    margin: 0 auto;
  }

  & h2 {
    max-width: 1328px;
    text-align: center;
    font-size: 36px;
    margin: 0 auto 48px;
    font-style: italic;
  }

  & iframe {
    width: 100%;
    height: 520px;
  }

  ${media.small} {
    padding: 32px 24px 0;

    & h2 {
      font-size: 32px;
    }
  }
`;

export const ServiceItem = styled.div`
  margin-bottom: 80px;
  width: 232px;
  /* width: calc((100% / 3) - (280px / 3)); */

  & svg {
    margin-bottom: 8px;
  }

  ${media.small} {
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    & svg {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;

export const Contact = styled.article`
  padding: 48px 24px;
  background-color: ${props => props.backgroundColor};

  & h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 32px;
    font-style: italic;
  }

  & .description {
    font-size: 24px;
    text-align: center;
    margin: 0 auto 40px;
    max-width: 600px;
  }

  & .form {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
  }

  & .form input {
    margin-bottom: 16px;
    border: 1px solid ${props => props.primaryColor};
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    outline: none;
    font-size: 14px;
    background-color: #ffffff;
  }

  & .form .couple {
    display: flex;
    justify-content: space-between;
  }

  & .form .couple input {
    width: calc(50% - 8px);
  }

  & .form textarea {
    margin-bottom: 16px;
    border: 1px solid ${props => props.primaryColor};
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    outline: none;
    font-size: 14px;
    background-color: #ffffff;
    min-height: 100px;
    max-width: 100%;
    min-width: 100%;
  }

  & .form .submit {
    outline: none;
    border: none;
    background-color: ${props => props.primaryColor};
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    padding: 16px;
    width: 100%;
    font-size: 14px;
    margin-top: 24px;
  }

  & .form .submit:disabled {
    background-color: #adadad;
    cursor: default;
  }
`;

export const Footer = styled.footer`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .logo {
    height: 80px;
    margin-bottom: 32px;
  }

  & .social-media {
    margin-bottom: 64px;
  }

  & .social-media > a:last-child {
    margin-left: 16px;
  }

  & .privacy {
    text-align: center;
    font-size: 14px;
    color: #000000;
  }
`;
