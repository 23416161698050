import styled from 'styled-components';
// import { media } from '../../helper';

export const Container = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #E0E3E5;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  color: #272A2D;

  & .room-name {
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 500;
  }

  & .room-detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 16px; */
  }

  & .room-detail > *:not(:last-child) {
    margin-right: 16px;
  }

  & .detail-item {
    display: flex;
    align-items: flex-end;
  }

  & .detail-item > svg {
    margin-right: 4px;
  }

  & .price-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  & .price-detail button {
    margin-bottom: 12px;
  }

  & .price-detail .base-price {
    font-size: 14px;
    color: #525969;
    text-decoration: line-through;
    margin-bottom: 4px;
  }

  & .price-detail .price {
    font-size: 16px;
    margin-bottom: 16px;
  }

  & .price-detail .label-offer {
    color: #1B5E20;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 8px;
    background-color: #E8F5E9;
    border-radius: 4px;
  }

  & .unavailable {
    color: red;
    font-weight: 500;
  }
`;
