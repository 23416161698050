/**
 * Input
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';

import { SInput } from './styles';

export default function Input({ width, ...restProps }) {
  return (
    <SInput width={width} {...restProps} />
  );
}
