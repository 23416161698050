import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { get } from 'lodash';
import Axios from 'axios';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import Input from '../../components/Input';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import Gallery from '../../components/Gallery';
import Select from '../../components/Select';
import messages from '../../getMessages';
import { BASE_URL } from '../../helper';

import {
  Container,
  Banner,
  ReservationForm,
  Hotels,
  CallToAction,
  Location,
  DrawerContainer,
  CommentsBanner,
} from './styles';

const containerStyle = {
  width: '100%',
  height: '460px',
  maxHeight: '80vh',
};

const center = {
  lat: 24.198,
  lng: -107.910,
};

const scaledSize = { width: 40, height: 40 };
const anchor = { x: 17, y: 36 };

// const images = [
//   '/promociones/navolato_699.jpg',
//   '/promociones/bellavista_699.jpg',
// ];

const options = [
  {
    value: 'la-paz',
    label: 'Hotel Maioris La Paz',
  },
  {
    value: 'guadalajara',
    label: 'Hotel Maioris Guadalajara',
  },
  {
    value: 'el-diez',
    label: 'Hotel Maioris El Diez',
  },
  {
    value: 'navolato',
    label: 'Hotel Maioris Navolato',
  },
  {
    value: 'kumate',
    label: 'Hotel Maioris Jesús Kumate',
  },
  {
    value: 'bellavista',
    label: 'Hotel Maioris Bellavista',
  },
];

const curr = new Date();
curr.setDate(curr.getDate() + 3);
const defaultDate = curr.toISOString().substr(0,10);

const initialBody = {
  hotel: null,
  llegada: '',
  salida: '',
  adultos: '',
  ninios: '',
  codigo: '',
}

const language = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
const newLanguage = language.includes('es') ? 'en' : 'es';
const newLanguageResponsive = language.includes('es') ? 'English' : 'Español';

export default function HomePage({ children, data }) {
  const isResponsive = useMediaQuery('@media only screen and (max-width: 992px)');

  const [anchorEl, setAnchorEl] = useState(null);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [body, setBody] = useState({ ...initialBody });

  useEffect(() => {
    fetchPromotions();
  }, []);

  useEffect(() => {
    if (isResponsive) {
      setBody({
        ...initialBody,
        llegada: defaultDate,
        salida: defaultDate,
      });
    }
  }, [isResponsive]);

  const fetchPromotions = async () => {
    try {
      const response = await Axios
        .get(`${BASE_URL}/promotions`);

      setImages((get(response, 'data.data', false) || []).map(item => item.url));
    } catch (e) {
      console.error(e);
    }
  }

  const handleClose = () => setAnchorEl(null);

  const handleOpenMenu = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleSetBody = (key, value) =>
    setBody(_body => ({
      ..._body,
      ...(key === 'hotel' ? {llegada: '', salida: ''} : {}),
      [key]: value,
    }));

  const getUrlBook = () => {
    let link = '/reservar';
    if (
      body.hotel ||
      body.llegada ||
      body.salida ||
      body.adultos ||
      body.ninios ||
      body.codigo
    ) {
      link = link.concat('?');
    }

    if (body.hotel) {
      link = link.concat(`hotel=${body.hotel.value}&`);
    }

    if (body.llegada) {
      link = link.concat(`llegada=${body.llegada}&`);
    }

    if (body.salida) {
      link = link.concat(`salida=${body.salida}&`);
    }

    if (body.adultos) {
      link = link.concat(`adultos=${body.adultos}&`);
    }

    if (body.ninios) {
      link = link.concat(`ninios=${body.ninios}&`);
    }

    if (body.codigo) {
      link = link.concat(`codigo=${body.codigo}&`);
    }

    if (link.charAt(link.length - 1) === '&') {
      link = link.slice(0, -1);
    }

    return link;
  };

  const handleChangeLanguage = () => {
    try {
      localStorage.setItem('language', newLanguage);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: Quitar validacion
  const minDate = get(body, 'hotel.value', '') === 'guadalajara' ? '2021-08-01' : moment().format('YYYY-MM-DD');
  const departureMinDate = moment(body.llegada ? body.llegada : new Date()).add(1, 'days').format('YYYY-MM-DD');

  return (
    <Container>
      <Banner background={get(data, 'landingConfig.home', '')}>
        <div className="layer" />
        <div className="over-content">
          <div className="content">
            <div className="space-between top-section">
              <div className="logo-section">
                <img src="/logo.png" alt="Logo hoteles Maioris" />
              </div>
              <nav>
                <Link to="/covid-19" onClick={handleCloseMenu}>
                  COVID-19
                </Link>
                <Link to="/restaurantes" onClick={handleCloseMenu}>
                  {messages.restaurants}
                </Link>
                <Link to="/eventos" onClick={handleOpenMenu}>
                  {messages.events}
                </Link>
                <Link to="/contacto" onClick={handleCloseMenu}>
                  {messages.contact}
                </Link>
                <Link to="/reservar" onClick={handleCloseMenu} className="reservar-button">
                  {messages.reservation}
                </Link>
                <button className="language" onClick={handleChangeLanguage}>
                  <span>{newLanguage}</span>
                </button>
              </nav>
              <MenuIcon onClick={() => setMenuOpen(true)} />
            </div>
            <div className="reservation">
              <div>
                <ReservationForm>
                  <h1>{messages.welcome}</h1>
                  <h3>{messages.welcomeMessage}</h3>
                  <Select
                    placeholder={messages.reservations.hotel}
                    options={options}
                    value={body.hotel}
                    onChange={value => handleSetBody('hotel', value)}
                  />
                  <div className="space-between" style={{ marginTop: 16 }}>
                    <Input
                      width="142px"
                      placeholder={messages.arrive}
                      type="date"
                      onChange={e => handleSetBody('llegada', e.target.value)}
                      value={body.llegada}
                      min={minDate}
                    />
                    <Input
                      width="142px"
                      placeholder={messages.exit}
                      type="date"
                      onChange={e => handleSetBody('salida', e.target.value)}
                      value={body.salida}
                      min={departureMinDate}
                    />
                  </div>
                  {/*<div className="space-between">
                    <Input
                      width="142px"
                      placeholder={messages.adults}
                      type="number"
                      onChange={e => handleSetBody('adultos', e.target.value)}
                      value={body.adultos}
                    />
                    <Input
                      width="142px"
                      placeholder={messages.children}
                      type="number"
                      onChange={e => handleSetBody('ninios', e.target.value)}
                      value={body.ninios}
                    />
                  </div>
                  <Input
                    width="300px"
                    placeholder={messages.code}
                    type="text"
                    onChange={e => handleSetBody('codigo', e.target.value)}
                    value={body.codigo}
                  />*/}
                  <Link to={getUrlBook()}>
                    {messages.book}
                  </Link>
                </ReservationForm>
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <Hotels>
        <h2>{messages.ourHotels}</h2>
        <div className="description">
          {messages.hotelsDescription}
        </div>
        <div className="list">
          <Card hotel="la-paz" data={data.places} />
          <Card hotel="guadalajara" data={data.places} />
          <Card hotel="el-diez" data={data.places} />
          <Card hotel="navolato" data={data.places} />
          <Card hotel="kumate" data={data.places} />
          <Card hotel="bellavista" data={data.places} />
        </div>
      </Hotels>
      <CallToAction background={get(data, 'landingConfig.promotions', '')}>
        <div className="layer" />
        <div className="content">
          <h2>{messages.ourPromotions}</h2>
          <h4>{messages.promorionsDesc}</h4>
          <button
            className="promotions"
            onClick={() => setGalleryOpen(true)}
          >
            {messages.seePromotions}
          </button>
          {false && <div className="restrictions">{messages.restrictions}</div>}
        </div>
      </CallToAction>
      <Location>
        <h2>{messages.ourPresence}</h2>
        <div className="description">
          {messages.presenceDesc}
        </div>
        <LoadScript
        googleMapsApiKey="AIzaSyBn_QTAsaNNI9gmqF9wcalcUNSXUwziltc"
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5}
        >
          <Marker
            id="navolato"
            position={{ lat: 24.7648925, lng: -107.6014242 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="kumate"
            position={{ lat: 24.758003, lng: -107.448389 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="bellavista"
            position={{ lat: 24.817865, lng: -107.4869909 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="guadalajara"
            position={{ lat: 20.5959649, lng: -103.3915505 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="el-diez"
            position={{ lat: 24.736679, lng: -107.455522 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
          <Marker
            id="la-paz"
            position={{ lat: 24.104697, lng: -110.3716468 }}
            icon={{
              url: './images/custom-marker.svg',
              anchor,
              scaledSize,
            }}
          />
        </GoogleMap>
      </LoadScript>
      </Location>
      <CommentsBanner background={get(data, 'landingConfig.comments', '')}>
        <div className="layer" />
        <div className="content">
          <h2>{messages.comments.title}</h2>
          <h4>{messages.comments.description}</h4>
          <Link to="/comentario">
            <button
              className="promotions"
            >
              {messages.comments.action}
            </button>
          </Link>
        </div>
      </CommentsBanner>
      <Footer />
      {!isResponsive && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      )}
      <Gallery
        images={images}
        hidden
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
      />
      <Drawer anchor="right" open={menuOpen} onClose={handleCloseMenu}>
        <DrawerContainer>
          <img src="/logo.png" alt="Logo hoteles Maioris" />
          <nav>
            <Link to="/covid-19" onClick={handleCloseMenu}>
              COVID-19
            </Link>
            <Link to="/restaurantes" onClick={handleCloseMenu}>
              {messages.restaurants}
            </Link>
            <Link to="/eventos" onClick={handleOpenMenu}>
              {messages.events}
            </Link>
            <Link to="/contacto" onClick={handleCloseMenu}>
              {messages.contact}
            </Link>
            <Link to="/reservar" onClick={handleCloseMenu} className="reservar-button">
              {messages.reservation}
            </Link>
            <Button
              color="secondary"
              className="language"
              variant="contained"
              onClick={handleChangeLanguage}
            >
              {newLanguageResponsive}
            </Button>
          </nav>
        </DrawerContainer>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      </Drawer>
    </Container>
  );
}
