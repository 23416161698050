export const mediaQueryXSmall = '@media (max-width: 576px)';
export const mediaQuerySmall = '@media (max-width: 768px)';
export const mediaQueryMedium = '@media (max-width: 992px)';
export const mediaQueryLarge = '@media (max-width: 1200px)';

export const media = {
  xSmall: mediaQueryXSmall,
  small: mediaQuerySmall,
  medium: mediaQueryMedium,
  large: mediaQueryLarge,
};


// DEV

// export const BASE_URL = 'http://localhost:8080/api';

// export const CONEKTA_KEYS = {
//   'la-paz': 'key_K7LuyqDqoDxq2NuiEoxqwmQ',
//   guadalajara: 'key_FaRYy7y6z3Ytn175vibVBnQ',
//   'el-diez': 'key_AFL51qNeos1AJbFNsqyMTcw',
//   navolato: 'key_DMqanhqyx7ykPCVrAENAo4A',
//   kumate: 'key_Hx2TyPWDuiEnkbxJ7XTeeog',
//   bellavista: 'key_MHwi4TDPqCrQkTeH2as8szQ',
// };

// PROD

export const BASE_URL = 'https://fabled-autonomy-317222.uc.r.appspot.com/api';

export const CONEKTA_KEYS = {
  'la-paz': 'key_VARnKzayDxrWcH6Uoyzwkiw',
  guadalajara: 'key_fVgbcAshyBLdjHqp1RPo76w',
  'el-diez': 'key_R7xGhzu9rvGyaAx73qGxYbQ',
  navolato: 'key_VZv96NmybGqXdX51jCUssqQ',
  kumate: 'key_UpvxNMtbwsijscW8T4rKiMg',
  bellavista: 'key_RpiraL4Lu8af2AedNsc94sA',
};

export const hotelsMap = {
  'la-paz': 'La Paz',
  guadalajara: 'Guadalajara',
  'el-diez': 'El Diez',
  navolato: 'Navolato',
  kumate: 'Kumate',
  bellavista: 'Bellavista',
};
