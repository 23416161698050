import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.section`
  padding-top: 80px;

  & .content-reservation {
    max-width: 1328px;
    margin: 0 auto;
    padding: 56px 24px;
  }

  & .content-reservation .block {
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
    padding: 24px 32px 40px;
    margin-bottom: 64px;
  }

  & .content-reservation h2 {
    font-size: 24px;
    font-weight: 500;
    padding-left: 16px;
    /* padding-bottom: 24px; */
    margin-bottom: 24px;
    /* border-bottom: 1px solid #DBD8D8; */
  }

  & .inputs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  & .inputs-list > * {
    width: calc((100% / 3) - (48px / 3));
  }

  & .inputs-list input {
    max-height: 42px;
  }

  & .inputs-list > *:nth-child(-n + 6) {
    margin-bottom: 24px;
  }

  & .inputs-list .couple-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .inputs-list .couple-inputs > * {
    width: 49%;
  }

  & .brief {
     display: flex;
     align-items: center;
     justify-content: space-between;
  }

  & .brief .cost {
    font-weight: 500;
    color: #060B5B;
    font-size: 24px;
  }

  & .brief button {
    border: none;
    outline: none;
    width: calc((100% / 3) - (48px / 3));
    padding: 11px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    background-color: #060B5B;
    border-radius: 4px;
    transition: background-color 0.25s;
  }

  & .brief button:disabled {
    background-color: #adadad;
    cursor: default;
  }

  & .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .card-list > * {
    margin-bottom: 40px;
    width: calc((100% / 3) - 16px) !important;
    cursor: pointer;
  }

  & .input-checkboxes {
    display: flex;
    flex-direction: column;
  }

  & .input-checkboxes > * {
    margin-bottom: 16px;
  }

  & .steps-container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 40px;
  }

  & .room-list {
    display: flex;
    flex-direction: column;
  }

  & .room-list > * {
    width: 100%;
    margin-bottom: 24px;
  }

  & .dates-rooms-section {
    display: flex;
    align-items: flex-start;
  }

  & .dates-rooms-section .left-section {
    margin-right: 32px;
    flex-grow: 1;
  }

  & .right-section {
    width: 424px;
    flex-grow: 0;
    background-color: #FFFFFF;
    border: 1px solid #E0E3E5;
    border-radius: 12px;
    padding: 24px;
  }

  & .hotel-name {
    color: #272A2D;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  & .date-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  & .date-inputs > * {
    width: calc(50% - 4px);
  }

  & .date-inputs h5 {
    font-weight: 500;
    padding-left: 4px;
    margin-bottom: 8px;
  }

  & .general-data {
    background-color: #FFFFFF;
    border: 1px solid #E0E3E5;
    border-radius: 12px;
    padding: 24px;
  }

  & .general-data h2 {
    color: #272A2D;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
  }

  & .general-data .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 32px;
  }

  & .general-data .form > * {
    width: calc(50% - 8px);
    margin-bottom: 24px;
  }

  & .general-data .full-width {
    width: 100% !important;
  }

  & .general-data .form .quarter-div {
    width: calc(25% - 8px);
  }

  & .error-message {
    margin-top: 4px;
    padding-left: 4px;
    color: red;
    font-size: 12px;
  }

  & .reservation-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .reservation-detail h1 {
    padding-bottom: 4px;
    border-bottom: 2px solid #D8B93C;
    margin-bottom: 32px;
  }

  & .masked-input {
    border: 1px solid #D8D5D5;
    border-radius: 4px;
    padding: 12px 8px;
    max-width: 100%;
    width: 100%;
    outline: none;
    background: #ffffff;
  }

  ${media.small} {
    & .content-reservation {
      padding: 24px;
    }

    & .inputs-list {
      flex-direction: column;
    }

    & .inputs-list > * {
      width: 100%;
      margin-bottom: 24px;
    }

    & .brief {
      flex-direction: column;
    }

    & .brief button {
      width: 100%;
      margin-top: 24px;
    }

    & .card-list {
      flex-direction: column;
    }

    & .card-list > * {
      width: 100% !important;
    }

    & .MuiStepper-root {
      padding: 0;
    }

    & .dates-rooms-section {
      flex-direction: column;
    }

    & .dates-rooms-section > div {
      width: 100%;
    }

    & .form {
      flex-direction: column;
    }

    & .form > * {
      width: 100% !important;
    }

    & .right-section {
      width: 100%;
    }
  }
`;

export const ResumeOrder = styled.div`
  background-color: #ECEFF1;
  padding: 16px;

  & .total-pay {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #272A2D;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  & .detail {
    color: #525969;
    font-size: 14px;
  }

  & .small-label {
    text-align: right;
    font-size: 12px;
    margin-top: 4px;
  }

  & .align-right {
    text-align: right;
  }

  & button {
    margin-top: 16px;
  }
`;
