import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.footer`
  background-color: #060B5B;
  padding: 32px 32px 72px 80px;
  color: #ffffff;

  & .logo {
    width: 180px;
    margin-bottom: 32px;
  }

  & .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1000px;
    max-width: 100%;
  }

  & .content .header {
    margin-bottom: 16px;
    color: #D8B93C;
    font-size: 16px;
    font-weight: bold;
  }

  & .content > div {
    display: flex;
    flex-direction: column;
  }

  & .content > div a {
    color: #ffffff;
    margin-bottom: 16px;
    font-size: 14px;
  }

  & .divider {
    height: 232px;
    width: 2px;
    background-color: #D6B73C;
  }

  & .social-media {
    display: flex;
  }

  & .social-media a {
    margin-right: 16px;
  }

  & .whatsapp-button {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9;
  }

  & .whatsapp-button:hover {
    z-index: 11;
  }

  & .whatsapp-button button {
    background-color: #37d043;
  }

  & .whatsapp-button img {
    width: 28px;
  }

  & .whatsapp-button svg {
    color: #ffffff;
  }

  & .whatsapp-button button:hover {
    background-color: #37d043;
  }

  ${media.small} {
    padding: 32px;

    & .content {
      flex-direction: column;
    }

    & .divider {
      display: none !important;
    }

    & .content .header {
      margin-top: 24px;
    }
  }
`;
