import React from 'react';
// import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Container,
  // Item,
} from './styles';

import messages from '../../getMessages';

export default function Politics({ children }) {
  return (
    <Container>
      <Header />
      <div className="politics-content">
        <h1>{messages.hotelPolitics}</h1>
        <div className="block">
          <div className="title">
            {messages.politics.politics}
          </div>
          <div className="detail">
            {messages.politics.paragraph1}
          </div>
          <div className="detail">
            {messages.politics.paragraph2}
          </div>
          <div className="detail">
            {messages.politics.paragraph3}
          </div>
        </div>
        <div className="block">
          <div className="title">
            {messages.politics.reservationPolitics}
          </div>
          <div className="subtitle">
            {messages.politics.checkin}
          </div>
          <div className="detail-reservation">
            {messages.politics.checkinDesc}
          </div>
          <div className="subtitle">
            {messages.politics.guarantee}
          </div>
          <div className="detail-reservation">
            {messages.politics.guaranteeDesc}
          </div>
          <div className="subtitle">
            {messages.politics.cancel}
          </div>
          <div className="detail-reservation">
            {messages.politics.cancelDesc}
          </div>
          <div className="subtitle">
            {messages.politics.notArrive}
          </div>
          <div className="detail-reservation">
            {messages.politics.notArriveDesc}
          </div>
          <div className="subtitle">
            {messages.politics.extra}
          </div>
          <div className="detail-reservation">
            {messages.politics.extraDesc}
          </div>
          <div className="subtitle">
            {messages.politics.children}
          </div>
          <div className="detail-reservation">
            {messages.politics.childrenDesc}
          </div>
          <div className="subtitle">
            {messages.politics.pets}
          </div>
          <div className="detail-reservation">
            {messages.politics.petsDes}
          </div>
          <div className="subtitle">
            {messages.politics.overSell}
          </div>
          <div className="detail-reservation">
            {messages.politics.overSellDesc}
          </div>
          <div className="subtitle">
            {messages.politics.specialPrice}
          </div>
          <div className="detail-reservation">
            {messages.politics.specialPriceDesc}
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  );
}
