/**
 *
 * Select
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';

const Container = styled.div`
  width: ${props => props.width || '100%'};
  max-width: 100%;
  font-size: 14px;

  & .label {
    color: #636363;
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

function Select({ width, label, ...restProps }) {
  const noOptionsMessage = () => (
    <span>
      No results
    </span>
  );

  return (
    <Container width={width}>
      {label && <div className="label">{label}</div>}
      <ReactSelect
        className="basic-single"
        {...restProps}
        noOptionsMessage={noOptionsMessage}
        styles={{
          indicatorSeparator: provided => ({
            ...provided,
            display: 'none',
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: '#6c6c6c',
          }),
          control: provided => ({
            ...provided,
            minHeight: 42,
            borderColor: '#d5d5d5',
            borderRadius: 8,
          }),
        }}
        menuPlacement="bottom"
        menuPosition="fixed"
      />
    </Container>
  );
}

Select.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
};

export default memo(Select);
