/**
 * Header
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';

import { Container, DrawerContainer } from './styles';
import messages from '../../getMessages';

const language = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
const newLanguage = language.includes('es') ? 'en' : 'es';
const newLanguageResponsive = language.includes('es') ? 'English' : 'Español';

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleOpenMenu = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleChangeLanguage = () => {
    try {
      localStorage.setItem('language', newLanguage);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const isResponsive = useMediaQuery('@media only screen and (max-width: 992px)');

  return (
    <Container>
      <Link to="/">
        <img src="/logo.png" className="logo" alt="Logo hoteles Maioris" />
      </Link>
      <nav>
        <Link to="/covid-19">COVID-19</Link>
        <Link to="/restaurantes">{messages.restaurants}</Link>
        <Link to="/eventos" onClick={handleOpenMenu}>{messages.events}</Link>
        <Link to="/contacto">{messages.contact}</Link>
        <Link to="/reservar" className="reservar-button">
          {messages.reservation}
        </Link>
        <button className="language" onClick={handleChangeLanguage}>
          <span>{newLanguage}</span>
        </button>
      </nav>
      <MenuIcon className="options-icon" onClick={() => setMenuOpen(true)} />
      <Drawer anchor="right" open={menuOpen} onClose={handleCloseMenu}>
        <DrawerContainer>
          <img src="/logo.png" alt="Logo hoteles Maioris" />
          <nav>
            <Link to="/covid-19" onClick={handleCloseMenu}>
              COVID-19
            </Link>
            <Link to="/restaurantes" onClick={handleCloseMenu}>
              {messages.restaurants}
            </Link>
            <Link to="/eventos" onClick={handleOpenMenu}>
              {messages.events}
            </Link>
            <Link to="/contacto" onClick={handleCloseMenu}>
              {messages.contact}
            </Link>
            <Link to="/reservar" onClick={handleCloseMenu} className="reservar-button">
              {messages.reservation}
            </Link>
            <Button
              color="secondary"
              className="language"
              variant="contained"
              onClick={handleChangeLanguage}
            >
              {newLanguageResponsive}
            </Button>
          </nav>
        </DrawerContainer>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      </Drawer>
      {!isResponsive && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/via-esperanza">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Esperanza
            </MenuItem>
          </Link>
          <Link to="/via-petra">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Salón Vía Petra
            </MenuItem>
          </Link>
          <Link to="/maioris-beach-club">
            <MenuItem style={{ color: '#000000' }} onClick={handleClose}>
              Maioris Beach Club
            </MenuItem>
          </Link>
        </Menu>
      )}
    </Container>
  );
}
