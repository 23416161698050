import styled from 'styled-components';
import { media } from '../../helper';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  box-shadow: 0 2px 4px 0 rgba(183, 181, 181, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 8;

  & .logo {
    width: 180px;
  }

  & nav {
    display: flex;
    align-items: center;
  }

  & nav a {
    text-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    color: #000000;
    font-size: 16px;
    transition: opacity 0.25s;
  }

  & nav a:hover {
    opacity: 0.9;
  }

  & nav a:not(:first-child) {
    margin-left: 48px;
  }

  & .language {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #060B5B;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-left: 24px;
    flex-shrink: 0;
    text-transform: uppercase;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }

  & .options-icon {
    display: none;
    color: #060B5B;
  }

  ${media.medium} {
    & nav {
      display: none;
    }

    & .top-section {
      padding: 0 16px;
    }

    & .options-icon {
      display: block;
    }

    & .reservation {
      justify-content: center;
    }
  }

  ${media.small} {
    & .logo {
      width: 140px;
    }
    & nav {
      display: none;
    }
  }
`;

export const DrawerContainer = styled.div`
  min-width: 280px;
  padding: 32px 24px;

  & > img {
    width: 160px;
    margin-bottom: 40px;
  }

  & nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & nav a {
    margin-bottom: 32px;
    color: #000000;
  }

  & .reservar-button {
    background: #D8B93C;
    padding: 12px 40px;
    border-radius: 4px;
  }
`;
